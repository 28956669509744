import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/flightreducer";
import axios from "axios";

const AppContext = createContext();
const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  isSingleLoading: true,
  flightResults:[],
  flightInfo: [],
  flightFareRules: '',
  session_id:'',
  flightDest:[]
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDestination = async (url) => {
    dispatch({ type: "SET_DEST_LOADING" });
    try {
      const res = await axios.get('https://book.freedom.travel/home/airport_destsApp?term='+url);
      const flightDest = await res.data;
      dispatch({ type: "SET_FLIGHT_DEST", payload: flightDest  })
    } catch (error) {
      dispatch({ type: "DEST_ERROR" });
  }
  };

  const getFLightSearch = async (json) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    try {
      const res = await axios.post("http://127.0.0.1:3000/availability", json);
      console.log("object of contaxt",res.data)
      const flightResults = await res.data.AirSearchResponse.AirSearchResult.FareItineraries;
      const session_id = res.data.AirSearchResponse.session_id
      dispatch({ type: "SET_FLIGHT_RESULTS", payload: flightResults  })
      dispatch({ type: "SET_SESSION_DATA", payload: session_id })
    } catch (error) {
      console.log(error)
      dispatch({ type: "FLIGHT_RESULTS_ERROR" });
    }
  };

  // my 2nd api call for single product

  const getFLightDetails = async (json) => {
    dispatch({ type: "SET_REVIDATE_LOADING" });
    try {
      const res = await axios.post("http://127.0.0.1:3000/revalidate",json);
      console.log(res.data)
      if (res.data.AirRevalidateResponse.AirRevalidateResult.IsValid !== "false" || res.data.AirRevalidateResponse.AirRevalidateResult.FareItineraries !== null ) {
        const flightInfo = await res.data.AirRevalidateResponse.AirRevalidateResult.FareItineraries;
        dispatch({ type: "SET_REVIDATE", payload: flightInfo });
      }else{
        dispatch({ type: "SET_REVIDATE_ERROR" });
      }
    } catch (error) {
      dispatch({ type: "SET_REVIDATE_ERROR" });
    }
  };

  const getFareRules = async (json) => {
    dispatch({ type: "SET_RULES_LOADING" });
    try {
      const res = await axios.post("http://127.0.0.1:3000/fare_rules",json);
      const flightFareRules = await res.data.FareRules1_1Response.FareRules1_1Result;
      dispatch({ type: "SET_FARE_RULES", payload: flightFareRules });
    } catch (error) {
      dispatch({ type: "SET_FARE_ERROR" });
    }
  };

  const booking = async (json) => {
    dispatch({ type: "SET_RULES_LOADING" });
    try {
      const res = await axios.post("http://127.0.0.1:3000/booking",json);
      console.log("flight contaxt",res.data)
      const flightbooking = await res.data;
      dispatch({ type: "SET_BOOKING", payload: flightbooking });
    } catch (error) {
      dispatch({ type: "SET_FARE_ERROR" });
    }
  };

  
  const saveBooking = async (json) => {
    dispatch({ type: "SET_RULES_LOADING" });
    try {
      const res = await axios.post("https://globalgds.co/nodejsapp/booking",json);
      console.log("flight contaxt",res.data)
      const savbookingdata = await res.data;
      dispatch({ type: "SET_SAVE_BOOKING", payload: savbookingdata });
    } catch (error) {
      dispatch({ type: "SET_FARE_ERROR" });
    }
  };


  return (
    <AppContext.Provider value={{ ...state,getDestination,getFLightSearch,getFLightDetails,getFareRules,booking,saveBooking }}>
      {children}
    </AppContext.Provider>
  );
};

// custom hooks
const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };