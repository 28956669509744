import { createContext, useContext, useReducer } from "react";
import axios from "axios";
import HotelReducer from "../reducer/hotelReducer";

const HotelStateContext = createContext();
const initialState = {};

const HotleProvider = ({children}) => {
  const [state, dispatch] = useReducer(HotelReducer, initialState);

  const hotelDestination = async (url) => {
    dispatch({ type: "SET_HOTEL_DEST_LOADING" });
    try {
      const res = await axios.get('https://www.trivamor.com/apphotel/hotel_dest?term='+url);
      const hotelDest = await res.data.data;
      dispatch({ type: "SET_HOTEL_DEST", payload: hotelDest  })
    } catch (error) {
      dispatch({ type: "DEST_ERROR" });
    }
  };

  const getHotelSearch = async (json) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://127.0.0.1:3000/hotel_search',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : JSON.stringify(json)
      };
      const res = await axios.request(config);
      console.log("object of contaxt",res.data)
      let hotelResults = await res.data;
      let session_id = res.data.status.sessionId
      let propertyType = [];let locality = []; let facilities = []; let city = [];let price=[]
      hotelResults.itineraries.forEach((curElem) => {
        locality.push(curElem.locality) 
        propertyType.push(curElem.propertyType)
        facilities.push(curElem.facilities)
        city.push(curElem.city)
        price.push(curElem.total)
      });
      locality = [ ...new Set(locality)]
      propertyType = [ ...new Set(propertyType)]
      city = [ ...new Set(city)]
      let mergedArray = [].concat(...facilities);
      let allfacilities = [...new Set(mergedArray)];
      let maxPrice = Math.max(...price);
      let minPrice = Math.min(...price);
      dispatch({ type: "SET_HOTEL_RESULTS", payload: hotelResults})
      dispatch({ type: "SET_SESSION_DATA", payload: session_id})
      dispatch({ type: "SET_FILTER_DATA", city ,allfacilities, propertyType, locality, maxPrice, minPrice })
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };
  //https://travelnext.works/api/hotel_trawexv6/filterResults
  const filterResults = async (json) => {
    //console.log("request of filters",json)
    dispatch({ type: "SET_RESULTS_LOADING" });
    try {
      const res = await axios.post("http://127.0.0.1:3000/filterResults", json);
      console.log("respose of hotel contaxt",res.data)
      if(res.data.itineraries){
        let hotelResults = await res.data;
        let session_id = await res.data.status.sessionId
         dispatch({ type: "SET_HOTEL_RESULTS", payload: hotelResults})
         dispatch({ type: "SET_SESSION_DATA", payload: session_id})
      }else{
        dispatch({ type: "HOTEL_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };

  //https://travelnext.works/api/hotel_trawexv6/hotelDetails?sessionId=&ltsessionId%3E%20&hotelId=&lthotelId%3E&productId=trx104&tokenId=&lttokenId%3E

  const gethotelDetails = async (sessionId,hotelId,productId,tokenId) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    let url = "http://127.0.0.1:3000/hotelDetails?sessionId="+sessionId+'&hotelId='+hotelId +'&productId='+ productId + '&tokenId=' +tokenId
    try {
      const res = await axios.get(url);
      //console.log("respose of hotel contaxt",url, res.data)
      if(res.data){
          let hotelDetail = await res.data;
         dispatch({ type: "SET_HOTEL_DETAILS", payload: hotelDetail})
      }else{
        dispatch({ type: "HOTEL_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };

  //https://travelnext.works/api/hotel_trawexv6/get_room_rates

  const get_room_rates = async (json) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    let url = "http://127.0.0.1:3000/get_room_rates"
    try {
      const res = await axios.post(url,json);
      //console.log("respose of rooms rate contaxt", json,res.data)
      if(res.data.roomRates.perBookingRates){
          let roomsRate = await res.data;
         dispatch({ type: "SET_ROOMS_RATE", payload: roomsRate})
      }else{
        dispatch({ type: "HOTEL_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };
  //https://travelnext.works/api/hotel_trawexv6/getNearByPlaces?latitude=28.657709081162334&longitude=77.18289837036127&radius=2000
  const getNearByPlaces = async (latitude,longitude,radius,) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    let url = "http://127.0.0.1:3000/getNearByPlaces?latitude="+ latitude +'&longitude='+longitude+'&radius='+radius;
    try {
      const res = await axios.get(url);
      let nearByPlaces = await res.data;
      dispatch({ type: "SET_NEARBY_PLACES", payload: nearByPlaces})
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };

  //	https://travelnext.works/api/hotel_trawexv6/get_rate_rules

  const get_rate_rules = async (json) => {
    dispatch({ type: "SET_RESULTS_LOADING" });
    let url = "http://127.0.0.1:3000/get_rate_rules"
    try {
      const res = await axios.post(url,json);
      console.log("respose of rooms rate contaxt",res.data)
      if(res.data.roomRates.perBookingRates){
          let roomsRules = await res.data;
         dispatch({ type: "SET_ROOMS_RULES", payload: roomsRules})
      }else{
        dispatch({ type: "HOTEL_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "HOTEL_ERROR" });
    }
  };

  //	https://travelnext.works/api/hotel_trawexv6/hotel_book

  const hotel_book = async (json) => {
    dispatch({ type: "SET_BOOKING_LOADING" });
    let url = "http://127.0.0.1:3000/hotel_book"
    try {
      const res = await axios.post(url,json);
      console.log("respose of booking of trawexnet contaxt",res.data)
      if(res.data.roomBookDetails){
          let hotelBook = await res.data;
         dispatch({ type: "SET_HOTEL_BOOK", payload: hotelBook})
         
      }else{
        dispatch({ type: "SET_BOOK_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "SET_BOOK_ERROR" });
    }
  };

  const setHotelBook = async (json) => {
    dispatch({ type: "SET_SAVE_LOADING" });
    let url = "https://groupy.live/reactApp/hotels/hotel_booking"
    try {
      const res = await axios.post(url,json);
      console.log("respose of save booking data contaxt",res.data)
      if(res.data){
          let hotelBooking = await res.data;
         dispatch({ type: "SET_HOTEL_SAVE", payload: hotelBooking})
      }else{
        dispatch({ type: "SET_SAVE_ERROR" });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "SET_SAVE_ERROR" });
    }
  };
  return (
    <HotelStateContext.Provider value={{ ...state,hotelDestination,getHotelSearch,filterResults,gethotelDetails,get_room_rates,getNearByPlaces,get_rate_rules,hotel_book,setHotelBook}}>
      {children}
    </HotelStateContext.Provider>
  );
};

const useHotelState = () => {
  const context = useContext(HotelStateContext);
  if (!context) {
    throw new Error("useHotelState must be used within a HotelProvider");
  }
  return context;
};



export { HotleProvider, useHotelState  };
